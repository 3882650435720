import React, { useEffect, useState } from "react";
import AOS from "aos";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import network from "./utils/Network";
import DynamicRouter from "./utils/DynamicRouter";
import LoadingIndicator from "./components/LoadingIndicator/LoadingIndicator";
import useScript from "./components/PageBlocks/hooks/useScript";

const App = () => {
  const statusURL = process.env.REACT_APP_STATUS_SNIPPET_URL;
  const [appData, setAppData] = useState(null);
  useScript(statusURL, "status");

  useEffect(() => {
    trackPromise(
      axios
        .all([
          axios.get(network.apiBase + "announcement"),
          axios.get(network.apiBase + "footer?populate=deep"),
          axios.get(network.apiBase + "urgent-banner"),
          axios.get(
            network.apiBase + "map-datas?pagination[limit]=50&populate=*",
          ),
          axios.get(
            network.apiBase + "pages?pagination[limit]=50&populate=deep",
          ),
        ])
        .then(
          axios.spread((announcement, footer, urgentBanner, mapDatas, pages) =>
            setAppData({
              announcement: announcement.data.data,
              footer: footer.data.data,
              urgentBanner: urgentBanner.data.data,
              mapDatas: mapDatas.data.data,
              pages: pages.data.data,
            }),
          ),
        )
        .catch((error) => {
          // TODO:
          // show some sort of an unstyled error screen if we get here
          console.log("error on main data pull:");
          console.log(error);
        }),
    );

    AOS.init({ duration: 600 });

    // AOS.refresh();
  }, []);

  return (
    <div className="app">
      <LoadingIndicator hasLogo={true} />
      {appData && (
        <DynamicRouter
          announcement={appData.announcement}
          footer={appData.footer}
          urgentBanner={appData.urgentBanner}
          mapDatas={appData.mapDatas}
          pages={appData.pages}
        />
      )}
    </div>
  );
};

export default App;
